import React from 'react'
import './index.scss'

const Footer = () =>
  <footer className='Footer'>
    <div className='copyright'>
      <span className='name'>SERGIO RODRIGUEZ</span> ©2018
    </div>
    <div>Icon Credits</div>
    <div>
      <div>D20 by HeadsOfBirds from the Noun Project | Sword by Ruslan Dezign  from the Noun Project</div>
      <div>Sword by Eucalyp from the Noun Project | treasure by dDara from the Noun Project</div>
      <div>Scroll by LSE Designs from the Noun Project | Magic Wand by dDara from the Noun Project</div>
    </div>
  </footer>

export default Footer
