import React from 'react'
import Section from '../Section'
import Intro from '../Intro'
import History from '../History'
import Footer from '../Footer'
import Stats from '../Stats'
import Treasures from '../Treasures'
import Divider from '../Divider'
// import Controls from '../Controls'
import './index.scss'

const App = () =>
  <div className='App'>
    {/* <Controls /> */}
    <Section id='intro'>
      <Intro />
    </Section>
    <Section>
      <Divider />
    </Section>
    <Section id='history'>
      <History />
    </Section>
    <Section>
      <Divider />
    </Section>
    <Section id='stats'>
      <Stats />
    </Section>
    <Section>
      <Divider />
    </Section>
    <Section id='treasures'>
      <Treasures />
    </Section>
    <Section>
      <Divider />
    </Section>
    <Footer />
  </div>

export default App
