import React from 'react'
import './index.scss'
import icon from '../../images/treasure.svg'

const Treasures = () =>
  <div className='Treasures'>
    <h3>
      <img className='section-icon' alt='Sword by Eucalyp from the Noun Project' src={icon} />
      Treasures
    </h3>
  </div>

export default Treasures
