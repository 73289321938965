import React from 'react'
import Field from '../Field'
import Grid from '@material-ui/core/Grid'
import icon from '../../images/sword.svg'
import './index.scss'

const Skills = () =>
  <div className='Skills'>
    <h3>
      <img className='section-icon' alt='Sword by Eucalyp from the Noun Project' src={icon} />
      Skills
    </h3>
    <ul>
      {data.map(Skill)}
    </ul>
  </div>

const Skill = ({ name, attribute, modifier, total, score }) =>
  <li className='skill' key={name}>
    <Field>
      <Grid container spacing={32} alignItems='flex-end'>
        <Grid item xs={5} className='name'>
          {name}
        </Grid>
        <Grid item xs={2} className='attribute'>
          +{attribute}
        </Grid>
        <Grid item xs={1}>
          +{modifier}
        </Grid>
        <Grid item xs={1}>
         =
        </Grid>
        <Grid item xs={1} className='total'>
          +{total}
        </Grid>
      </Grid>
    </Field>
  </li>

const data = [
  {
    name: 'Javascript',
    attribute: 'INT',
    modifier: '4',
    total: '10'
  },
  {
    name: 'React',
    attribute: 'INT',
    modifier: '2',
    total: '8'
  },
  {
    name: 'Redux',
    attribute: 'INT',
    modifier: '2',
    total: '8'
  },
  {
    name: 'React-Native',
    attribute: 'INT',
    modifier: '2',
    total: '8'
  },
  {
    name: 'Expo',
    attribute: 'CHA',
    modifier: '2',
    total: '8'
  },
  {
    name: 'HTML',
    attribute: 'CHA',
    modifier: '4',
    total: '10'
  },
  {
    name: 'CSS',
    attribute: 'CHA',
    modifier: '3',
    total: '9'
  },
  {
    name: 'Bootstrap',
    attribute: 'CHA',
    modifier: '2',
    total: '8'
  },
  {
    name: 'NodeJS',
    attribute: 'INT',
    modifier: '3',
    total: '9'
  },
  {
    name: 'Java',
    attribute: 'WIS',
    modifier: '2',
    total: '8'
  },
  {
    name: 'SQL',
    attribute: 'WIS',
    modifier: '4',
    total: '10'
  },
  {
    name: 'C#',
    attribute: 'WIS',
    modifier: '0',
    total: '5'
  },
  {
    name: 'VB.Net',
    attribute: 'WIS',
    modifier: '0',
    total: '5'
  }
]
export default Skills
