import React from 'react'
import Field from '../Field'
import './index.scss'
import icon from '../../images/magic_wand.svg'

const Feats = () =>
  <div className='Feats'>
    <h3>
      <img className='section-icon flip' alt='Sword by Eucalyp from the Noun Project' src={icon} />
      Feats
    </h3>
    <ul>
      {data.map(Feat)}
    </ul>
  </div>

const Feat = ({ name, description }) =>
  <li className='feat' key={name}>
    <Field>
      <span className='name'>
        {name}
      </span>
      {description ? <span className='description'>({description})</span> : null}
    </Field>
  </li>

const data = [
  {
    name: 'Orkin Man',
    description: '+2 vs Bugs'
  },
  {
    name: 'Agility',
    description: 'No Damage on Ref Saves during Stand-Up'
  },
  {
    name: 'Skill Focus (Slack)',
    description: '+1 to all communication checks'
  },
  {
    name: 'Florida Man',
    description: '+2 to Fort Saves vs Poison; +2 AC vs giant reptiles'
  }
]
export default Feats
