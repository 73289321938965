import React from 'react'
import Grid from '@material-ui/core/Grid'
import Field from '../Field'
import portraitImg from '../../images/avatar.jpg'
// import swordIconImg from '../../images/sword.png'

import './index.scss'

const Intro = () =>
  <div className='Intro'>
    <Grid container justify='center' alignItems='center' spacing={32}>
      <Grid item xs={6} sm={4} md={3}>
        <img className='portrait' src={portraitImg} alt='Sergio Rodriguez character portrait' />
      </Grid>
    </Grid>
    <Grid container justify='center' alignItems='flex-start' spacing={32}>
      <Grid item xs={12} sm={12} md={12}>
        <Field label='name'>
          {/* <img src={swordIconImg} style={{width: '2em'}} alt='Sergio Rodriguez character portrait' /> */}
          <span className='name'>Sergio Rodriguez</span>
        </Field>
      </Grid>
      <Grid item xs={12} sm={8} md={6}>
        <Field label='class'>Developer</Field>
      </Grid>
      <Grid item xs={12} sm={4} md={6}>
        <Field label='specialization'>Full Stack</Field>
      </Grid>
      <Grid item xs={6} sm={4} md={4}>
        <Field label='alignment'>Chaotic Neutral</Field>
      </Grid>
      <Grid item xs={3} sm={4} md={4}>
        <Field label='level'>10</Field>
      </Grid>
      <Grid item xs={3} sm={4} md={4}>
        <Field label='homeland'>Florida</Field>
      </Grid>
    </Grid>
  </div>

export default Intro
