import React from 'react'
import Grid from '@material-ui/core/Grid'
import Field from '../Field'
import './index.scss'
import icon from '../../images/swordAndStone.svg'

const History = () =>
  <div className='History'>
    <h3>
      <img className='section-icon' alt='Sword by Eucalyp from the Noun Project' src={icon} />
      Character Background
    </h3>
    {data.map(EmploymentRecord)}
  </div>

const EmploymentRecord = ({ position, employer, duration, description, rewards }) =>
  <Grid container key={duration} justify='flex-start' spacing={16}>
    <Grid item xs={12} sm={6}>
      <Field>
        <span className='position'>{position}</span>
      </Field>
      <Field>
        <span className='employer'>{employer}</span>
      </Field>
    </Grid>
    <Grid item xs={12} sm={6}>
      <p>{description}</p>
      <Field label='Rewards'>
        <span className='rewards'>{rewards}</span>
      </Field>
    </Grid>
  </Grid>

const data = [
  {
    position: 'Senior Application Developer',
    employer: 'Auto Data Direct, Inc',
    duration: '2015-2019',
    description: `
      As a member of the Auto Data Direct, Inc. team, our hero led a flexible team in designing and implementing the ADDTag project,
      a tool for titling and registering vehicles in the state of Florida.  He also worked closely on a number of
      automotive data applications, security improvements, and internal tools for customer service use.
      He also led a raid on a warren of goblins.`,
    rewards: '3200gp, 5sp, 3 art objects, +2 Helm of Glamour'
  },
  {
    position: 'Senior Application Developer',
    employer: 'Florida Department of Economic Opportunity',
    duration: '2012-2015',
    description: `
      In the land of the Florida Department of Economic Opportunity, our hero
      crafted data reports and web visualization tools to aid in the management of the Wagner-Peyser,
      Workforce Investment Act, and other federally mandated programs.  He also provided daily unemployment reports
      used by the Department and reported to the federal government on a daily basis.  Sergio also worked to
      create and maintain a number of rapidly-developed web tools and sites, including HireFloridaGrads.com.`,
    rewards: '2pp, 2713gp, battered mace, the Haunted Diamond of Germunk'
  }
]
export default History
