import React from 'react'
import Grid from '@material-ui/core/Grid'
import Skills from '../Skills'
import Feats from '../Feats'

import './index.scss'

const Stats = () =>
  <div className='Stats'>

    <Grid container justify='flex-start' spacing={32}>
      <Grid item xs={12} sm={6}>
        <Skills />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Feats />
      </Grid>
    </Grid>
  </div>

export default Stats
