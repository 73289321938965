import React from 'react'
import Divider from '../Divider'
import './index.scss'

const Field = ({ label, children }) =>
  <div className='Field'>
    <div className='value'>{children}</div>
    <Divider height='3px' />
    <div className='label'>{label}</div>
  </div>

export default Field
