import React from 'react'
import Grid from '@material-ui/core/Grid'
import './index.scss'

const Section = ({ children, id, fullScreen }) =>
  <Grid container justify='center' alignItems='flex-start' spacing={0}>
    <Grid item xs={11} sm={10} md={9}>

      <section className={'Section' + (fullScreen ? ' full-screen' : '')} id={id}>
        {children}
      </section>
    </Grid>
  </Grid>

export default Section
